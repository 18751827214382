<template lang="pug">
include ../../../configs/template
div.row.text-left.pa-4
  div.col-sm-12.col-md-6
    +data-info('country', 'getDirectoryObject({ id: sailorPassport.country, value: "country" }).value')
  div(v-if="sailorPassport.country === 2 && sailorPassport.port").col-sm-12.col-md-6
    +data-info('port', 'getDirectoryObject({ id: sailorPassport.port, value: "ports" })?.[namelang]')
  div(v-else).col-sm-12.col-md-6
    +data-info('port', 'sailorPassport.other_port')
  div.col-sm-12.col-md-6
    +data-info('number', 'sailorPassport.number_document')
  div.col-sm-12.col-md-6
    +data-info('captain', 'sailorPassport.captain')
  div.col-sm-12.col-md-6
    +data-info('dateIssue', 'getDateFormat(sailorPassport.date_start)')
  div.col-sm-12.col-md-6
    +data-info('dateTermValid', 'getDateFormat(sailorPassport.date_end)')
  div(v-if="sailorPassport.date_renewal").col-sm-12.col-md-6
    +data-info('dateRenewal', 'getDateFormat(sailorPassport.date_renewal)')
  div(v-if="sailorPassport.created_by").col-sm-12.col-md-6
    +data-info('recordAuthor', 'sailorPassport.created_by.name')
  div(v-if="sailorPassport.created_by").col-sm-12.col-md-6
    +data-info('createDate', 'sailorPassport.created_by.date')
  div(v-if="sailorPassport.verificated_by").col-sm-12.col-md-6
    +data-info('verificationDate', 'sailorPassport.verificated_by.date')
  div(v-if="sailorPassport.comments?.length && sailorPassport.status_document === STATUSES_STATEMENT_CONSTANTS.SAILOR_PASSPORT.INVALID").col-sm-12
    h5.p-0.m-0 {{$t('rejectionReasonStatement')}}:
    div(v-if="sailorPassport.comments?.length").col-sm-12
      div(v-for="comment in sailorPassport.comments" :key="comment.id").pa-2
        div(v-if="comment.additional_info.reason")
          +data-info('rejectionReason','getDirectoryObject({ value: "rejectionReasons", id: comment.additional_info.reason })[namelang]')
        div(v-if="checkAccess('backOffice')").pt-2.py-3
          +data-info('createdBy', 'comment.created_by.name')
        div.pt-2.py-3
          +data-info('dateCreate', 'new Date(comment.created_by.date).toLocaleString()')
        div.pt-2.py-3
          +data-info('setComment', 'comment.comment')
  div.col-sm-12.col-md-6
    +data-info-status('status', 'getDirectoryObject({ id: sailorPassport.status_document, value: "statuses" })[namelang]', 'getStatus(sailorPassport.status_document)')
</template>

<script>
import { getStatus, getDateFormat } from '@/mixins/main'
import { mapGetters, mapState } from 'vuex'
import { STATUSES_STATEMENT_CONSTANTS } from '@/configs/constants'
import { checkAccess } from '@/mixins/permissions'
export default {
  name: 'SailorPassportInfo',
  props: {
    sailorPassport: { type: Object, default: () => ({}) }
  },
  data () {
    return {
      checkAccess,
      getStatus,
      STATUSES_STATEMENT_CONSTANTS,
      getDateFormat
    }
  },
  computed: {
    ...mapGetters(['getDirectoryObject']),
    ...mapState({
      namelang: state => state.main.lang === 'en' ? 'name_eng' : 'name_ukr'
    })
  }
}
</script>
